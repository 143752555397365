import axios from "axios";
import { useEffect, useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import Access from "../AccessPage/Access";

export default function StripeProvider(){
    const stripePromise = loadStripe('pk_test_boSWCK1Eh9gpDHuJyzpWb8t4');

    const [clientSecret, setClientSecret] = useState(null)
    const [options, setOptions] = useState({})

    useEffect(() => {
        axios.get("https://fppaymentintent.flowepay.workers.dev")
        .then(res => {
            setClientSecret(res.data.clientSecret); 
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        setOptions({
            clientSecret: clientSecret
        })
    }, clientSecret)


    if(options !== null && clientSecret != null){
        return(
            <Elements stripe={stripePromise} options={options}>
                <Access/>
            </Elements>
        )
    }
}