import { UserIcon } from '@heroicons/react/24/outline'
import logo from "../logo.svg"
import axios from 'axios'
import { useState, useEffect } from 'react'
import { AddressElement, Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CheckoutForm from '../Checkout/CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';

export default function Access() {

  const stripe = useStripe();
  const elements = useElements(); 

  const products = [
    {
      id: 1,
      name: "Women's Basic Tee",
      href: '#',
      price: '$32.00',
      color: 'Gray',
      size: 'S',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/checkout-page-05-product-01.jpg',
      imageAlt: "Front of women's basic tee in heather gray.",
    },
    {
      id: 2,
      name: "Women's Basic Tee",
      href: '#',
      price: '$32.00',
      color: 'Gray',
      size: 'S',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/checkout-page-05-product-01.jpg',
      imageAlt: "Front of women's basic tee in heather gray.",
    },
    {
      id: 3,
      name: "Women's Basic Tee",
      href: '#',
      price: '$32.00',
      color: 'Gray',
      size: 'S',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/checkout-page-05-product-01.jpg',
      imageAlt: "Front of women's basic tee in heather gray.",
    }
  ]



  const handleSubmit = async (event) => {
    event.preventDefault();

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (error) {
      // handle error
    }
  };


  return (
  
    <div className="bg-white">
      <header className=" inset-0 flex items-center justify-center px-4 py-4 sm:px-6 sm:py-8 lg:px-8 w-screen">
          <img alt="" src={logo} className="h-20 w-auto place-items-center" />
      </header>

      <main className="mx-auto max-w-7xl px-4 pb-16 pt-4 sm:px-6 sm:pb-24 sm:pt-8 lg:px-8 xl:px-2 xl:pt-14">
        <h1 className="sr-only">Checkout</h1>

        <div className="mx-auto grid max-w-lg grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="mx-auto w-full max-w-lg">
            <h2 className="pb-10 text-lg font-medium text-gray-900">Order summary</h2>

            <div className="flow-root">
              <ul role="list" className="-my-6 divide-y divide-gray-200">
                {products.map((product) => (
                  <li key={product.id} className="flex space-x-6 py-6">
                    <img
                      alt={product.imageAlt}
                      src={product.imageSrc}
                      className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                    />
                    <div className="flex-auto">
                      <div className="space-y-1 sm:flex sm:items-start sm:justify-between sm:space-x-6">
                        <div className="flex-auto space-y-1 text-sm font-medium">
                          <h3 className="text-gray-900">
                            <a href={product.href}>{product.name}</a>
                          </h3>
                          <p className="text-gray-900">{product.price}</p>
                          <p className="hidden text-gray-500 sm:block">{product.color}</p>
                          <p className="hidden text-gray-500 sm:block">{product.size}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
              <div className="flex justify-between">
                <dt>Subtotal</dt>
                <dd className="text-gray-900">$104.00</dd>
              </div>
              <div className="flex justify-between">
                <dt>Taxes</dt>
                <dd className="text-gray-900">$8.32</dd>
              </div>
              <div className="flex justify-between">
                <dt>Shipping</dt>
                <dd className="text-gray-900">$14.00</dd>
              </div>
              <div className="flex justify-between border-t border-gray-200 pt-6 text-gray-900">
                <dt className="text-base">Total</dt>
                <dd className="text-base">$126.32</dd>
              </div>
            </dl>
          </div>

          <div className="mx-auto w-full max-w-lg">

            <form className='w-full max-w-md lg:col-span-5 lg:pt-2'
              onSubmit={handleSubmit}
            >
              <h2 className="text-lg font-medium text-gray-900 mb-10">Information</h2>

              <LinkAuthenticationElement />
              <h3 className='text-lg font-medium text-gray-900 mb-10 mt-6'>Shipping</h3>
              <AddressElement options={{mode: 'shipping', allowedCountries: ['IT']}} />
              <h3 className='text-lg font-medium text-gray-900 mb-10 mt-6'>Payment</h3>
              <PaymentElement />
            
              <div className="mt-10 flex space-x-2">
                <div className="flex h-5 items-center">
                  <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <label htmlFor="terms" className="text-sm text-gray-500">
                  I have read the terms and conditions and agree to the sale of my personal information to the highest
                  bidder.
                </label>
              </div>

              <button
                type="submit"
                className="mt-6 w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
              >
                Pay
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  )
  

}